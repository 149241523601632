import PropTypes from "prop-types"
// @mui
import { useTheme } from "@mui/material/styles"
import styled from "styled-components"
import { Box } from "@mui/material"
import Link from "next/link"
import Image from "next/image"
import Label from "./Label"
import FP_BLACK from "public/logo/flowpoint_black.png"
import FP_WHITE from "public/logo/flowpoint_white.png"
// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.any,
}

const LogoWrapper = styled.div`
    padding: 3px;
    max-height: 50px;
    display: flex;
    align-items: center;
    max-width: 150px;

    transition: 1s;
    .text-color {
        fill: ${(props) => props.color || "black"};
    }
`

const RouterLink = styled(Link)`
    text-decoration: none;
`

const StyledBox = styled(Box)`
    align-items: center;
    justify-content: space-between;
    display: flex;
    // background-color:${(props) => (props.isOffset ? "initial" : "white")};
    padding: 10px;
    border-radius: 10px;

    h1 {
        color: #0a2540;
        font-size: 20px;
        text-decoration: none;
    }
`

export default function Logo({ disabledLink = false, sx, hasTitle, titleColor, isOffset, isOffsetHeader, ...rest }) {
    const theme = useTheme()

    const logo = (
        <StyledBox sx={{ ...sx, cursor: "pointer" }}>
            <LogoWrapper>
                {/* <LogoImg className="logo-class"/> */}
                {isOffsetHeader ? (
                    <Image height={24} width={146} alt='logo flowpoint.ai' src={FP_BLACK} />
                ) : (
                    <Image height={24} width={146} alt='logo flowpoint.ai' src={FP_WHITE} />
                )}
            </LogoWrapper>
        </StyledBox>
    )

    if (disabledLink) {
        return <>{logo}</>
    }

    return <RouterLink href='/'>{logo}</RouterLink>
}
