// next
import { useEffect, useState } from "react"
import NextLink from "next/link"
// @mui
import { styled } from "@mui/material/styles"
import { Grid, Link, Divider, Container, Typography, Stack } from "@mui/material"
// routes
import { EXTERNAL_PATHS, PATH_PAGE } from "../../routes/paths"
// components
import Logo from "../../components/Logo"
import SocialsButton from "../../components/SocialsButton"
import CookiePopup from "../../components/CookiePopup"
import { LoadingButton } from "@mui/lab"
// ----------------------------------------------------------------------

const LINKS = [
    {
        headline: "Resources",
        children: [
            { name: "Documentation", href: EXTERNAL_PATHS.docs },
            { name: "Blog", href: EXTERNAL_PATHS.blog },
            { name: "FAQs", href: PATH_PAGE.faqs },
            { name: "GDPR Statement ", href: PATH_PAGE.gdpr },
        ],
    },
    {
        headline: "Legal",
        children: [
            { name: "Privacy Policy", href: "/privacy" },
            { name: "Data Protection Agreement", href: "/data-protection" },
            { name: "Cookie Notice", href: "/cookie-notice" },
            { name: "Terms of Use", href: "/terms" },
            { name: "Manage cookies", type: "manage_cookies" },
        ],
    },
]

const RootStyle = styled("div")(({ theme }) => ({
    position: "relative",
    backgroundColor: theme.palette.background.default,
}))

const Text = styled("p")(({ theme }) => ({
    lineHeight: " 1.5714285714285714",
    fontSize: "0.875rem",
    fontWeight: "500",
    textDecoration: "none",
    color: "#637381",
    display: "block",
}))

// ----------------------------------------------------------------------

export default function MainFooter() {
    const [isShown, setIsShown] = useState(false)
    const [isOpen, setIsOpen] = useState(true)
    const handleClick = () => {
        setIsShown(true)
    }

    return (
        <RootStyle>
            <Divider />
            <Container sx={{ pt: 10 }}>
                <Grid container justifyContent={{ xs: "center", md: "space-between" }} sx={{ textAlign: { xs: "center", md: "left" } }}>
                    <Grid item xs={12}>
                        <Logo
                            sx={{
                                mx: { xs: "auto", md: "inherit", color: "rgb(10, 37, 64) !important" },
                                display: "flex",
                                justifyContent: { xs: "center", md: "flex-start" },
                            }}
                            hasTitle={true}
                            isOffsetHeader
                        />
                    </Grid>
                    <Grid item xs={8} md={3}>
                        <Typography
                            variant='body2'
                            sx={{
                                pr: { md: 0 },
                                fontFamily: "Poppins, sans-serif",
                                textAlign: { xs: "center", md: "left" },
                                lineHeight: 1.57143,
                                fontSize: "0.875rem",
                                fontWeight: "500",
                            }}
                        >
                            The workspace Actionable, at scale
                        </Typography>

                        <Stack direction='row' justifyContent={{ xs: "center", md: "flex-start" }} sx={{ mt: 5, mb: { xs: 5, md: 0 } }}>
                            <SocialsButton sx={{ mx: 0.5 }} />
                        </Stack>
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Stack spacing={5} direction={{ xs: "column", md: "row" }} justifyContent='space-between'>
                            {LINKS.map((list, index) => (
                                <Stack key={`${list.headline}${index + 1}`} spacing={2}>
                                    <Typography component='p' variant='overline'>
                                        {list.headline}
                                    </Typography>
                                    {list.children.map((link, index) => {
                                        return link.type !== "manage_cookies" ? (
                                            <NextLink key={`${link.name}${index + 1}`} href={link.href} passHref>
                                                <Link color='inherit' variant='caption' sx={{ display: "block", color: "text.secondary" }}>
                                                    {link.name}
                                                </Link>
                                            </NextLink>
                                        ) : (
                                            <Text
                                                key='manageButton'
                                                style={{ cursor: "pointer", color: "text.secondary", fontSize: "12px", fontWeight: 400 }}
                                                onClick={handleClick}
                                            >
                                                Manage cookies
                                            </Text>
                                        )
                                    })}
                                </Stack>
                            ))}
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Stack
                            spacing={5}
                            direction={{ xs: "column", md: "row" }}
                            justifyContent='space-between'
                            sx={{ marginTop: { xs: "40px", md: "0px" } }}
                        >
                            <Stack spacing={2}>
                                <Typography component='p' variant='overline'>
                                    Contact
                                </Typography>

                                {/* <NextLink
                 
                  href="mailto:hello@flowpoint.ai"
                  passHref
                >
                  <Link
                    color="inherit"
                    variant="body2"
                    sx={{ display: "block" }}
                  >
                    hello@flowpoint.ai
                  </Link>
                </NextLink> */}
                                <Text>FLOWPOINT ANALYTICS LTD</Text>
                                <Text>Company Number 14068900</Text>
                                <Text>83-86 Prince Albert Road, London, UK</Text>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>

                <Typography
                    component='p'
                    variant='subtitle2'
                    sx={{
                        mt: 10,
                        pb: 5,
                        fontSize: 13,
                        textAlign: { xs: "center", md: "left" },
                        fontFamily: "Poppins, sans-serif",
                        fontWeight: 500,
                    }}
                >
                    © 2023. All rights reserved @Flowpoint
                </Typography>
            </Container>
            {isShown && (
                <div onClick={() => setIsShown(false)}>
                    <CookiePopup isManageCookie={isOpen} />
                </div>
            )}
        </RootStyle>
    )
}
