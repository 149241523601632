import PropTypes from "prop-types"
import { useState, useEffect } from "react"
// next
import NextLink from "next/link"
import { useRouter } from "next/router"
// @mui
import { alpha, styled } from "@mui/material/styles"
import { Box, List, Link, Drawer, Collapse, ListItemText, ListItemIcon, ListItemButton, Button } from "@mui/material"
// config
import { NAVBAR } from "../../config"
// components
import Logo from "../../components/Logo"
import Iconify from "../../components/Iconify"
import Scrollbar from "../../components/Scrollbar"
import { IconButtonAnimate } from "../../components/animate"
import { NavSectionVertical } from "../../components/nav-section"
import { openPopupWidget, InlineWidget } from "react-calendly"
import { DASHBOARD_URL } from "../../config"

// ----------------------------------------------------------------------
const StyledButton = styled(Button)(({ theme }) => ({
    color: "white",
    border: "1px solid",
    marginLeft: "15px",
}))

const ButtonStyle = styled(Button)(({ theme }) => ({
    ...theme.typography.body2,
    height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
    textTransform: "capitalize",
    color: theme.palette.text.secondary,
}))

const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
    ...theme.typography.body2,
    height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
    textTransform: "capitalize",
    color: theme.palette.text.secondary,
}))

// ----------------------------------------------------------------------

MenuMobile.propTypes = {
    isOffset: PropTypes.bool,
    isHome: PropTypes.bool,
    navConfig: PropTypes.array,
}

export default function MenuMobile({ isOffset, isHome, navConfig }) {
    const { pathname } = useRouter()

    const [open, setOpen] = useState(false)
    const [openCases, setOpenCases] = useState(false)
    const [openStudies, setOpenStudies] = useState(false)

    const [drawerOpen, setDrawerOpen] = useState(false)

    const router = useRouter()
    const index = router?.query?.index
    const name = router?.query?.name

    const getDashboardUrl = () => {
        const isEventUrl = router.pathname?.startsWith("/events")
        const isPartnerUrl = router.pathname?.startsWith("/partners")
        const isCustomDashboard = isEventUrl || isPartnerUrl
        return isCustomDashboard
            ? `${DASHBOARD_URL}/auth/register?ref=${name}&type=${isEventUrl ? "events" : "partners"}&id=${index}`
            : DASHBOARD_URL
    }

    useEffect(() => {
        if (drawerOpen) {
            handleDrawerClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const handleOpen = () => {
        setOpen(!open)
    }
    const handleOpenCases = () => {
        setOpenCases(!openCases)
    }
    const handleOpenStudies = () => {
        setOpenStudies(!openStudies)
    }

    const handleDrawerOpen = () => {
        setDrawerOpen(true)
    }

    const handleDrawerClose = () => {
        setDrawerOpen(false)
    }

    return (
        <>
            <IconButtonAnimate
                onClick={handleDrawerOpen}
                aria-label='button-nav'
                sx={{
                    ml: 1,
                    ...(isHome && { color: "common.white" }),
                    ...(isOffset && { color: "text.primary" }),
                }}
            >
                <Iconify icon={"eva:menu-2-fill"} />
            </IconButtonAnimate>

            <Drawer
                open={drawerOpen}
                onClose={handleDrawerClose}
                ModalProps={{ keepMounted: true }}
                PaperProps={{ sx: { pb: 5, width: 260 } }}
            >
                <Scrollbar>
                    <Logo sx={{ mx: 2.5, my: 3 }} isOffsetHeader={true} hasTitle={true} />

                    <List disablePadding>
                        {navConfig.map((link) => (
                            <MenuMobileItem
                                key={link.title}
                                item={link}
                                isOpen={open}
                                onOpen={handleOpen}
                                isOpenCases={openCases}
                                onOpenCases={handleOpenCases}
                                isOpenStudies={openStudies}
                                onOpenStudies={handleOpenStudies}
                                isHome={isHome}
                                isOffset={isOffset}
                            />
                        ))}
                    </List>

                    <StyledButton
                        variant='contain'
                        onClick={() => {
                            if (typeof window !== "undefined" && window.fbq) {
                                window.fbq("track", "GetStartedButton")
                            }
                        }}
                        sx={{
                            background: "#122920",
                        }}
                        target='_blank'
                        // href="https://dashboard.flowpoint.ai/auth/register?membershipPlan=starter"
                        href={getDashboardUrl()}
                    >
                        Start your free trial
                    </StyledButton>
                </Scrollbar>
            </Drawer>
        </>
    )
}

// ----------------------------------------------------------------------

MenuMobileItem.propTypes = {
    isOpen: PropTypes.bool,
    isOpenCases: PropTypes.bool,
    isOpenStudies: PropTypes.bool,
    item: PropTypes.shape({
        children: PropTypes.array,
        icon: PropTypes.any,
        path: PropTypes.string,
        title: PropTypes.string,
    }),
    onOpen: PropTypes.func,
    onOpenCases: PropTypes.func,
    onOpenStudies: PropTypes.func,
}

function MenuMobileItem({ item, isOpen, isOpenCases, isHome, onOpen, onOpenCases, isOpenStudies, onOpenStudies, isOffset }) {
    const { pathname } = useRouter()
    const { title, path, icon, children } = item

    const isActive = pathname === path

    if (title === "Resources") {
        return (
            <>
                <ListItemStyle onClick={onOpen}>
                    {/* <ListItemIcon>{icon}</ListItemIcon> */}
                    <ListItemText disableTypography primary={title} />
                    <Iconify
                        icon={isOpen ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
                        sx={{ width: 16, height: 16, ml: 1 }}
                    />
                </ListItemStyle>

                <Collapse in={isOpen} timeout='auto' unmountOnExit>
                    <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
                        <NavSectionVertical
                            navConfig={children}
                            // sx={{
                            //   '& .MuiList-root:last-of-type .MuiListItemButton-root': {
                            //     height: 200,
                            //     backgroundSize: 'cover',
                            //     backgroundPosition: 'center',
                            //     bgcolor: 'background.neutral',
                            //     backgroundRepeat: 'no-repeat',
                            //     backgroundImage:
                            //       'url(https://minimal-assets-api.vercel.app/assets/illustrations/illustration_dashboard.png)',
                            //     '& > *:not(.MuiTouchRipple-root)': { display: 'none' },
                            //   },
                            // }}
                        />
                    </Box>
                </Collapse>
            </>
        )
    }

    if (title === "Use cases") {
        return (
            <>
                <ListItemStyle onClick={onOpenCases}>
                    {/* <ListItemIcon>{icon}</ListItemIcon> */}
                    <ListItemText disableTypography primary={title} />
                    <Iconify
                        icon={isOpenCases ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
                        sx={{ width: 16, height: 16, ml: 1 }}
                    />
                </ListItemStyle>

                <Collapse in={isOpenCases} timeout='auto' unmountOnExit>
                    <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
                        <NavSectionVertical navConfig={children} />
                    </Box>
                </Collapse>
            </>
        )
    }
    if (title === "Case studies") {
        return (
            <>
                <ListItemStyle onClick={onOpenStudies}>
                    {/* <ListItemIcon>{icon}</ListItemIcon> */}
                    <ListItemText disableTypography primary={title} />
                    <Iconify
                        icon={isOpenStudies ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
                        sx={{ width: 16, height: 16, ml: 1 }}
                    />
                </ListItemStyle>

                <Collapse in={isOpenStudies} timeout='auto' unmountOnExit>
                    <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
                        <NavSectionVertical navConfig={children} />
                    </Box>
                </Collapse>
            </>
        )
    }
    if (title === "Features") {
        return (
            <>
                <ListItemStyle onClick={onOpenStudies}>
                    {/* <ListItemIcon>{icon}</ListItemIcon> */}
                    <ListItemText disableTypography primary={title} />
                    <Iconify
                        icon={isOpenStudies ? "eva:arrow-ios-downward-fill" : "eva:arrow-ios-forward-fill"}
                        sx={{ width: 16, height: 16, ml: 1 }}
                    />
                </ListItemStyle>

                <Collapse in={isOpenStudies} timeout='auto' unmountOnExit>
                    <Box sx={{ display: "flex", flexDirection: "column-reverse" }}>
                        <NavSectionVertical navConfig={children} />
                    </Box>
                </Collapse>
            </>
        )
    }
    const onClick = () => {
        openPopupWidget({ url: "https://calendly.com/flowpoint-vlad/intro" })
        if (typeof window !== "undefined" && window.fbq) {
            window.fbq("track", "ContactUsButton")
        }
    }

    if (title === "Contact us") {
        return (
            <ListItemStyle onClick={onContactUsClick} component={Button}>
                {/* <ListItemIcon>{icon}</ListItemIcon> */}
                <ListItemText disableTypography primary={title} />
            </ListItemStyle>
            // <ButtonStyle
            //   onClick={onContactUsClick}
            // >
            //      {/* <ListItemIcon>{icon}</ListItemIcon> */}
            //   {title}
            // </ButtonStyle>
        )
    }

    if (title === "Documentation") {
        return (
            <ListItemStyle href={path} target='_blank' rel='noopener' component={Link}>
                {/* <ListItemIcon>{icon}</ListItemIcon> */}
                <ListItemText disableTypography primary={title} />
            </ListItemStyle>
        )
    }

    return (
        <NextLink href={path} passHref>
            <ListItemStyle
                sx={{
                    ...(isActive && {
                        color: "primary.main",
                        fontWeight: "fontWeightMedium",
                        bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                    }),
                }}
            >
                {/* <ListItemIcon>{icon}</ListItemIcon> */}
                <ListItemText disableTypography primary={title} />
            </ListItemStyle>
        </NextLink>
    )
}
