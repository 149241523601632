// next
import { useRouter } from "next/router"
// @mui
import { styled, useTheme, alpha } from "@mui/material/styles"

import { Box, Button, AppBar, Toolbar, Container } from "@mui/material"
import { m } from "framer-motion"
import Link from "next/link"
import styledComponent from "styled-components"
// hooks
import useOffSetTop from "../../hooks/useOffSetTop"
import useResponsive from "../../hooks/useResponsive"
// utils
import { openPopupWidget } from "react-calendly"
import cssStyles from "../../utils/cssStyles"
// config
import { HEADER } from "../../config"
// components
import Logo from "../../components/Logo"
import Label from "../../components/Label"
//
import MenuDesktop from "./MenuDesktop"
import MenuMobile from "./MenuMobile"
import navConfig from "./MenuConfig"
import { varHover, varTranHover } from "../../components/animate"
import { DASHBOARD_URL } from "../../config"
import { gtag } from "../../utils/analytics"

const StyledButton = styledComponent(Button)`
color:white;
border:1px solid ${({ isoffsetheader, ...rest }) => (isoffsetheader === "true" ? "#0a2540" : "#1229204")};
background: ${({ isoffsetheader, ...rest }) => (isoffsetheader === "true" ? "#0a2540" : "#1229204")};
margin - right: 15px;
:hover{
    border: 1px solid  ${({ isoffsetheader, ...rest }) => (isoffsetheader === "true" ? "#122920" : "#1229204")};
    background: ${({ isoffsetheader, ...rest }) => (isoffsetheader === "true" ? "#122920" : "#1229204")};
    color: white;

}


`

const StyledLink = styled(Button)`
color: ${(props) => (props.isOffsetHeader ? "#0a2540" : "white")};
margin - right: 15px;
border: none;
background: transparent!important;
font - weight: 600;
box - shadow: none;
:hover{
    color: ${(props) => (props.isOffsetHeader ? "#0a2540" : "white")};
    opacity: 0.48;
    border: none;
    background: transparent!important;
} `
// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: HEADER.MOBILE_HEIGHT,

    transition: theme.transitions.create(["height", "background-color"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up("md")]: {
        height: HEADER.MAIN_DESKTOP_HEIGHT,
    },
}))

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    margin: "auto",
    borderRadius: "50%",
    position: "absolute",
    width: `calc(100 % - 48px)`,
    boxShadow: theme.customShadows.z8,
}))

// ----------------------------------------------------------------------

export default function MainHeader({ currentOffer }) {
    const { offsetTop: isOffset, offsetHeader: isOffsetHeader } = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT)

    const theme = useTheme()

    const { pathname } = useRouter()

    const isDesktop = useResponsive("up", "md")

    const isHome = pathname === "/"

    const router = useRouter()
    const name = router?.query?.name
    const index = router?.query?.index

    const getDashboardUrl = () => {
        const isEventUrl = router.pathname?.startsWith("/events")
        const isPartnerUrl = router.pathname?.startsWith("/partners")
        const isCustomDashboard = isEventUrl || isPartnerUrl
        return isCustomDashboard
            ? `${DASHBOARD_URL}/auth/register?ref=${name}&type=${isEventUrl ? "events" : "partners"}&id=${index}`
            : DASHBOARD_URL
    }

    const scrollTo = (id) => {
        setTimeout(() => {
            // document
            //   .querySelector("#trydemo")
            //   .scrollIntoView({ behavior: "smooth" })
            const yOffset = 200
            const element = document.querySelector(id)
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset

            window.scrollTo({ top: y, behavior: "smooth" })
        }, 300)
    }
    const onClick = () => {
        openPopupWidget({ url: "https://calendly.com/flowpoint-vlad/intro" })
        if (typeof window !== "undefined" && window.fbq) {
            window.fbq("track", "TalkToSalesButton")
        }
        gtag_report_conversion()
    }
    function gtag_report_conversion() {
        gtag("event", "conversion", {
            send_to: "AW-11014059734/aCWdCKC1tJ0YENbt9IMp",
        })
        return false
    }
    return (
        <AppBar sx={{ boxShadow: "none", background: "none", marginTop: currentOffer ? (isDesktop ? 6 : 10) : 2 }} elevation={0}>
            <ToolbarStyle disableGutters>
                <Container
                    style={{
                        borderRadius: 10,
                        padding: 10,

                        transition: "1s",

                        // background: "lightgrey",
                        backdropFilter: `blur(${6}px)`,
                        WebkitBackdropFilter: `blur(${6}px)`, // Fix on Mobile
                        backgroundColor: alpha("#fff", 0.8),
                        height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
                        border: "1px solid rgb(211 217 218 / 24%)",
                    }}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Logo hasTitle={true} isOffset={isOffset} isOffsetHeader={true} titleColor={"white"} />

                    <Box sx={{ flexGrow: 1 }} />

                    {isDesktop && <MenuDesktop isOffset={true} isHome={isHome} navConfig={navConfig} />}
                    {isDesktop && (
                        <m.div
                            variants={varHover(1.2)}
                            transition={varTranHover()}
                            whileTap={{ scale: 0.9 }}
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 0.2 },
                            }}
                        >
                            <StyledButton
                                variant='contain'
                                onClick={() => onClick()}
                                sx={{
                                    background: "#fff",
                                    color: "#122920",
                                    border: "2px solid #122920",
                                    borderRadius: "40px",
                                    "&:hover": {
                                        border: "2px solid #122920",
                                        background: "#122920",
                                        color: "white",
                                    },
                                }}
                                target='_blank'
                                isoffsetheader='true'
                                // href={getDashboardUrl()}
                                // href="https://dashboard.flowpoint.ai/auth/register?membershipPlan=starter"
                            >
                                Talk to sales
                            </StyledButton>
                        </m.div>
                    )}
                    {!isDesktop && <MenuMobile isOffset={true} isHome={isHome} navConfig={navConfig} />}
                </Container>
            </ToolbarStyle>
        </AppBar>
    )
}
