import React from "react"

// Lazy load illustrations
export const DocIllustration = React.lazy(() => import("./illustration_doc"))
export const SeoIllustration = React.lazy(() => import("./illustration_seo"))
export const UploadIllustration = React.lazy(() => import("./illustration_upload"))
export const SeverErrorIllustration = React.lazy(() => import("./illustration_500"))
export const PageNotFoundIllustration = React.lazy(() => import("./illustration_404"))
export const MotivationIllustration = React.lazy(() => import("./illustration_motivation"))
export const ComingSoonIllustration = React.lazy(() => import("./illustration_coming_soon"))
export const MaintenanceIllustration = React.lazy(() => import("./illustration_maintenance"))
export const OrderCompleteIllustration = React.lazy(() => import("./illustration_order_complete"))
export const BookingIllustration = React.lazy(() => import("./illustration_booking"))
export const CheckInIllustration = React.lazy(() => import("./illustration_checkin"))
export const CheckOutIllustration = React.lazy(() => import("./illustration_checkout"))

// Lazy load icons
export const SentIcon = React.lazy(() => import("./icon_sent"))
export const PlanFreeIcon = React.lazy(() => import("./icon_plan_free"))
export const PlanStarterIcon = React.lazy(() => import("./icon_plan_starter"))
export const PlanPremiumIcon = React.lazy(() => import("./icon_plan_premium"))
