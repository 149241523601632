// routes
import { EXTERNAL_PATHS, PATH_AUTH, PATH_DOCS, PATH_PAGE, PATH_CASES, PATH_FEATURES } from "../../routes/paths"
// components
import { PATH_AFTER_LOGIN } from "../../config"
// components
import Iconify from "../../components/Iconify"

// ----------------------------------------------------------------------

const ICON_SIZE = {
    width: 22,
    height: 22,
}

const menuConfig = [
    // {
    //   title: 'Home',
    //   icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    //   path: '/',
    // },
    // {
    //   title: 'Components',
    //   icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    //   path: PATH_PAGE.components,
    // },

    // {
    //   title: 'Demo',
    //   icon: <Iconify icon={'eva:book-open-fill'} {...ICON_SIZE} />,
    //   path: "#trydemo",
    // },
    {
        title: "Features",
        path: "/",
        icon: <Iconify icon={"ooui:logo-wikimedia-discovery"} {...ICON_SIZE} />,
        children: [
            {
                subheader: "Features",
                items: [
                    { title: "AI-Recommendations", path: PATH_FEATURES.aiRecommendations },
                    { title: "Funnel Analytics", path: PATH_FEATURES.funnelAnalytics },
                    { title: "Simple Analytics Reports", path: PATH_FEATURES.reports },
                    { title: "Website Analysis", path: PATH_FEATURES.websiteAnalysis },
                    { title: "Ava, AI Chatbot", path: PATH_FEATURES.ava },
                ],
            },
        ],
    },

    {
        title: "Resources",
        icon: <Iconify icon={"bx:book"} {...ICON_SIZE} />,
        path: "/resources",
        children: [
            {
                subheader: "Resources and Tools",
                items: [
                    { title: "Documentation", path: EXTERNAL_PATHS.docs },
                    { title: "Free Tools", path: PATH_PAGE.freeTools },
                    { title: "FAQs", path: PATH_PAGE.faqs },
                    { title: "Blog", path: EXTERNAL_PATHS.blog },
                    { title: "Product Update", path: PATH_PAGE.changelog },
                ],
            },

            // {
            //   subheader: 'News',
            //   items: [
            //     { title: 'Lorem ipsum', path: PATH_AUTH.loginUnprotected },
            //     { title: 'Lorem ipsum', path: PATH_AUTH.registerUnprotected },
            //     { title: 'Lorem ipsum', path: PATH_AUTH.resetPassword },
            //     { title: 'Lorem ipsum', path: PATH_AUTH.verify },
            //   ],
            // },
        ],
    },
    // {
    //   title: 'How it works',
    //   icon: <Iconify icon={'icon-park-outline:game-ps'} {...ICON_SIZE} />,
    //   path: "/#howitworks",
    // },

    {
        title: "Pricing",
        icon: <Iconify icon={"ion:pricetag-sharp"} {...ICON_SIZE} />,
        path: "/pricing",
    },
    // {
    //   title: 'About',
    //   icon: <Iconify icon={'fluent:people-team-32-filled'} {...ICON_SIZE} />,
    //   path: "/about-us",
    // },

    // { title: 'Contact us', path: PATH_PAGE.contact, icon: <Iconify icon={'eva:phone-call-fill'} {...ICON_SIZE} />, },
    // {
    //   title: 'Contact',
    //   icon: <Iconify icon={'fluent:contact-card-28-filled'} {...ICON_SIZE} />,
    //   path: "/contact-us",
    // },

    // {
    //   title: 'Use cases',
    //   path: '/',
    //   icon: <Iconify icon={'ooui:logo-wikimedia-discovery'} {...ICON_SIZE} />,
    //   children: [
    //     {
    //       subheader: '',
    //       items: [
    //         { title: 'Data Analytics', path: PATH_CASES.dataAnalytics },
    //         { title: 'AI Marketing Analyst', path: PATH_CASES.aiMarketingAnalyst },
    //         { title: 'Improve conversion rates', path: PATH_CASES.improveConversionRates },
    //         { title: 'Optimise SEO', path: PATH_CASES.optimiseSEO },
    //       ],
    //     },
    //     {
    //       subheader: '',
    //       items: [
    //         { title: 'Boost E-commerce sales', path: PATH_CASES.boostSales },
    //         { title: 'Understand where users drop off', path: PATH_CASES.dropoff },
    //         { title: 'Real-time error monitoring', path: PATH_CASES.monitoring },
    //         { title: 'Optimise PPC campaigns', path: PATH_CASES.PPC },
    //         { title: 'Generate data driven insights', path: PATH_CASES.aiInsights },
    //       ],
    //     },

    //   ],
    // },
    {
        title: "Case studies",
        path: "/",
        icon: <Iconify icon={"ooui:logo-wikimedia-discovery"} {...ICON_SIZE} />,
        children: [
            {
                subheader: "Case study",
                items: [
                    { title: "eCommerce business", path: PATH_CASES.ecommerce },
                    { title: "B2C web application", path: PATH_CASES.b2c },
                    { title: "Service provider", path: PATH_CASES.serviceProvider },
                ],
            },
        ],
    },
    {
        title: "Log in",
        icon: <Iconify icon={"bx:log-in"} {...ICON_SIZE} />,
        path: "https://dashboard.flowpoint.ai",
        target: "_blank",
    },
]

export default menuConfig
