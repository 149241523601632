import PropTypes from "prop-types"
import { m } from "framer-motion"
import { useState, useEffect } from "react"
// next
import NextLink from "next/link"
import { useRouter } from "next/router"
// @mui
import { styled } from "@mui/material/styles"
import { Box, Link, Grid, List, Stack, Popover, ListSubheader, CardActionArea, Button } from "@mui/material"
// components
import Iconify from "../../components/Iconify"

import { openPopupWidget, InlineWidget } from "react-calendly"

// ----------------------------------------------------------------------

const ButtonStyle = styled(Button)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
    padding: 0,
    marginRight: theme.spacing(5),
    transition: theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shorter,
    }),
    "&:hover": {
        opacity: 0.48,
        textDecoration: "none",
    },
}))

const LinkStyle = styled(Box)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
    cursor: "pointer",
    marginRight: theme.spacing(5),
    transition: theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shorter,
    }),
    "&:hover": {
        opacity: 0.48,
        textDecoration: "none",
    },
}))

const ListItemStyle = styled(Link)(({ theme }) => ({
    ...theme.typography.body2,
    display: "flex",
    fontWeight: 400,
    alignItems: "center",
    padding: 0,
    marginTop: theme.spacing(3),
    color: theme.palette.text.secondary,
    transition: theme.transitions.create("color"),
    "&:hover": {
        color: theme.palette.text.primary,
    },
}))

// ----------------------------------------------------------------------

MenuDesktop.propTypes = {
    isHome: PropTypes.bool,
    isOffset: PropTypes.bool,
    navConfig: PropTypes.array,
}

export default function MenuDesktop({ isOffset, isHome, navConfig }) {
    const { pathname } = useRouter()
    const [open, setOpen] = useState(false)
    const [openCases, setOpenCases] = useState(false)

    const [openFeatures, setOpenFeatures] = useState(false)
    const [openStudies, setOpenStudies] = useState(false)

    useEffect(() => {
        if (open) {
            handleClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        if (openCases) {
            handleCloseCases()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])
    useEffect(() => {
        if (openFeatures) {
            handleCloseFeatures()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    useEffect(() => {
        if (openStudies) {
            handleCloseStudies()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const handleOpenCases = () => {
        setOpenCases(true)
    }
    const handleOpenFeatures = () => {
        setOpenFeatures(true)
    }

    const handleCloseCases = () => {
        setOpenCases(false)
    }
    const handleCloseFeatures = () => {
        setOpenFeatures(false)
    }
    const handleOpenStudies = () => {
        setOpenStudies(true)
    }

    const handleCloseStudies = () => {
        setOpenStudies(false)
    }

    return (
        <Stack direction='row'>
            {navConfig.map((link) => (
                <MenuDesktopItem
                    key={link.title}
                    item={link}
                    isOpen={open}
                    isOpenCases={openCases}
                    isOpenFeatures={openFeatures}
                    isOpenStudies={openStudies}
                    onOpen={handleOpen}
                    onClose={handleClose}
                    onOpenCases={handleOpenCases}
                    onOpenFeatures={handleOpenFeatures}
                    onCloseFeatures={handleCloseFeatures}
                    onCloseCases={handleCloseCases}
                    onOpenStudies={handleOpenStudies}
                    onCloseStudies={handleCloseStudies}
                    isOffset={isOffset}
                    isHome={isHome}
                />
            ))}
        </Stack>
    )
}

// ----------------------------------------------------------------------

IconBullet.propTypes = {
    type: PropTypes.oneOf(["item", "subheader"]),
}

function IconBullet({ type = "item" }) {
    return (
        <Box sx={{ width: 24, height: 16, display: "flex", alignItems: "center" }}>
            <Box
                component='span'
                sx={{
                    ml: "2px",
                    width: 4,
                    height: 4,
                    borderRadius: "50%",
                    bgcolor: "currentColor",
                    ...(type !== "item" && { ml: 0, width: 8, height: 2, borderRadius: 2 }),
                }}
            />
        </Box>
    )
}

// ----------------------------------------------------------------------

MenuDesktopItem.propTypes = {
    isHome: PropTypes.bool,
    isOffset: PropTypes.bool,
    isOpen: PropTypes.bool,
    isOpenCases: PropTypes.bool,
    isOpenFeatures: PropTypes.bool,
    onOpenFeatures: PropTypes.func,
    onCloseFeatures: PropTypes.func,
    onCloseCases: PropTypes.func,
    onOpenCases: PropTypes.func,
    isOpenStudies: PropTypes.bool,
    onCloseStudies: PropTypes.func,
    onOpenStudies: PropTypes.func,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    item: PropTypes.shape({
        path: PropTypes.string,
        title: PropTypes.string,
        children: PropTypes.array,
    }),
}

function MenuDesktopItem({
    item,
    isHome,
    isOpen,
    isOpenCases,
    isOpenFeatures,
    onOpenFeatures,
    onCloseFeatures,
    isOpenStudies,
    isOffset,
    onOpen,
    onClose,
    onOpenCases,
    onCloseCases,
    onOpenStudies,
    onCloseStudies,
}) {
    const { pathname } = useRouter()

    const { title, path, children } = item

    const isActive = (path) => pathname === path

    if (title === "Resources") {
        return (
            <>
                <LinkStyle
                    key={title}
                    onClick={onOpen}
                    sx={{
                        display: "flex",
                        cursor: "pointer",
                        fontWeight: 500,
                        alignItems: "center",
                        ...(isHome && { color: "common.white" }),
                        ...(isOffset && { color: "text.primary" }),
                        ...(isOpen && { opacity: 0.48 }),
                    }}
                >
                    Resources
                    <Iconify
                        icon={isOpen ? "eva:arrow-ios-upward-fill" : "eva:arrow-ios-downward-fill"}
                        sx={{ ml: 0.5, width: 16, height: 16 }}
                    />
                </LinkStyle>

                <Popover
                    open={isOpen}
                    anchorReference='anchorPosition'
                    anchorPosition={{ top: 100, left: 0 }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={onClose}
                    PaperProps={{
                        sx: {
                            px: 3,
                            pt: 5,
                            pb: 3,
                            right: 16,
                            m: "auto",
                            borderRadius: 2,
                            maxWidth: (theme) => 400, //theme.breakpoints.values.lg,
                            boxShadow: (theme) => theme.customShadows.z24,
                        },
                    }}
                >
                    <Grid container spacing={3}>
                        {children.map((list) => {
                            const { subheader, items } = list

                            return (
                                <Grid key={subheader} item xs={12} md={subheader === "Dashboard" ? 6 : 6}>
                                    <List disablePadding>
                                        <ListSubheader
                                            disableSticky
                                            disableGutters
                                            sx={{
                                                display: "flex",
                                                lineHeight: "unset",
                                                alignItems: "center",
                                                color: "text.primary",
                                                typography: "overline",
                                            }}
                                        >
                                            <IconBullet type='subheader' /> {subheader}
                                        </ListSubheader>

                                        {items.map((item) => (
                                            <NextLink key={item.title} href={item.path} passHref>
                                                <ListItemStyle
                                                    underline='none'
                                                    sx={{
                                                        ...(isActive(item.path) && {
                                                            color: "text.primary",
                                                            typography: "subtitle2",
                                                        }),
                                                    }}
                                                >
                                                    {item.title === "Use cases" ? (
                                                        <CardActionArea
                                                            sx={{
                                                                py: 5,
                                                                px: 10,
                                                                borderRadius: 2,
                                                                color: "primary.main",
                                                                bgcolor: "background.neutral",
                                                            }}
                                                        >
                                                            <Box
                                                                component={m.img}
                                                                whileTap='tap'
                                                                whileHover='hover'
                                                                variants={{
                                                                    hover: { scale: 1.02 },
                                                                    tap: { scale: 0.98 },
                                                                }}
                                                                src='https://minimal-assets-api.vercel.app/assets/illustrations/illustration_dashboard.png'
                                                            />
                                                        </CardActionArea>
                                                    ) : (
                                                        <>
                                                            <IconBullet />
                                                            {item.title}
                                                        </>
                                                    )}
                                                </ListItemStyle>
                                            </NextLink>
                                        ))}
                                    </List>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Popover>
            </>
        )
    }

    if (title === "Use cases") {
        return (
            <>
                <LinkStyle
                    onClick={onOpenCases}
                    sx={{
                        display: "flex",
                        fontWeight: 500,
                        cursor: "pointer",
                        alignItems: "center",
                        ...(isHome && { color: "common.white" }),
                        ...(isOffset && { color: "text.primary" }),
                        ...(isOpenCases && { opacity: 0.48 }),
                    }}
                >
                    Use cases
                    <Iconify
                        icon={isOpenCases ? "eva:arrow-ios-upward-fill" : "eva:arrow-ios-downward-fill"}
                        sx={{ ml: 0.5, width: 16, height: 16 }}
                    />
                </LinkStyle>

                <Popover
                    open={isOpenCases}
                    anchorReference='anchorPosition'
                    anchorPosition={{ top: 100, left: 300 }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={onCloseCases}
                    PaperProps={{
                        sx: {
                            px: 3,
                            pt: 5,
                            pb: 3,
                            right: 16,
                            m: "auto",
                            borderRadius: 2,
                            maxWidth: (theme) => 600, //theme.breakpoints.values.lg,
                            boxShadow: (theme) => theme.customShadows.z24,
                        },
                    }}
                >
                    <Grid container spacing={3}>
                        {children.map((list) => {
                            const { subheader, items } = list

                            return (
                                <Grid key={subheader} item xs={12} md={subheader === "Dashboard" ? 6 : 6}>
                                    <List disablePadding>
                                        <ListSubheader
                                            disableSticky
                                            disableGutters
                                            sx={{
                                                display: "flex",
                                                lineHeight: "unset",
                                                alignItems: "center",
                                                color: "text.primary",
                                                typography: "overline",
                                            }}
                                        >
                                            <IconBullet type='subheader' /> {subheader}
                                        </ListSubheader>

                                        {items.map((item) => (
                                            <NextLink key={item.title} href={item.path} passHref>
                                                <ListItemStyle
                                                    underline='none'
                                                    sx={{
                                                        ...(isActive(item.path) && {
                                                            color: "text.primary",
                                                            typography: "subtitle2",
                                                        }),
                                                    }}
                                                >
                                                    {item.title === "Use cases" ? (
                                                        <CardActionArea
                                                            sx={{
                                                                py: 5,
                                                                px: 10,
                                                                borderRadius: 2,
                                                                color: "primary.main",
                                                                bgcolor: "background.neutral",
                                                            }}
                                                        >
                                                            <Box
                                                                component={m.img}
                                                                whileTap='tap'
                                                                whileHover='hover'
                                                                variants={{
                                                                    hover: { scale: 1.02 },
                                                                    tap: { scale: 0.98 },
                                                                }}
                                                                src='https://minimal-assets-api.vercel.app/assets/illustrations/illustration_dashboard.png'
                                                            />
                                                        </CardActionArea>
                                                    ) : (
                                                        <>
                                                            <IconBullet />
                                                            {item.title}
                                                        </>
                                                    )}
                                                </ListItemStyle>
                                            </NextLink>
                                        ))}
                                    </List>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Popover>
            </>
        )
    }
    if (title === "Case studies") {
        return (
            <>
                <LinkStyle
                    onClick={onOpenStudies}
                    sx={{
                        display: "flex",
                        fontWeight: 500,
                        cursor: "pointer",
                        alignItems: "center",
                        ...(isHome && { color: "common.white" }),
                        ...(isOffset && { color: "text.primary" }),
                        ...(isOpenStudies && { opacity: 0.48 }),
                    }}
                >
                    Case studies
                    <Iconify
                        icon={isOpenStudies ? "eva:arrow-ios-upward-fill" : "eva:arrow-ios-downward-fill"}
                        sx={{ ml: 0.5, width: 16, height: 16 }}
                    />
                </LinkStyle>

                <Popover
                    open={isOpenStudies}
                    anchorReference='anchorPosition'
                    anchorPosition={{ top: 100, left: 700 }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={onCloseStudies}
                    PaperProps={{
                        sx: {
                            px: 3,
                            pt: 5,
                            pb: 3,
                            right: 16,
                            m: "auto",
                            borderRadius: 2,
                            maxWidth: (theme) => 300, //theme.breakpoints.values.lg,
                            boxShadow: (theme) => theme.customShadows.z24,
                        },
                    }}
                >
                    <Grid container spacing={3}>
                        {children.map((list) => {
                            const { subheader, items } = list

                            return (
                                <Grid key={subheader} item xs={12} md={12}>
                                    <List disablePadding>
                                        <ListSubheader
                                            disableSticky
                                            disableGutters
                                            sx={{
                                                display: "flex",
                                                lineHeight: "unset",
                                                alignItems: "center",
                                                color: "text.primary",
                                                typography: "overline",
                                            }}
                                        >
                                            <IconBullet type='subheader' /> {subheader}
                                        </ListSubheader>

                                        {items.map((item) => (
                                            <NextLink key={item.title} href={item.path} passHref>
                                                <ListItemStyle
                                                    underline='none'
                                                    sx={{
                                                        ...(isActive(item.path) && {
                                                            color: "text.primary",
                                                            typography: "subtitle2",
                                                        }),
                                                    }}
                                                >
                                                    {item.title === "Case studies" ? (
                                                        <CardActionArea
                                                            sx={{
                                                                py: 5,
                                                                px: 10,
                                                                borderRadius: 2,
                                                                color: "primary.main",
                                                                bgcolor: "background.neutral",
                                                            }}
                                                        >
                                                            <Box
                                                                component={m.img}
                                                                whileTap='tap'
                                                                whileHover='hover'
                                                                variants={{
                                                                    hover: { scale: 1.02 },
                                                                    tap: { scale: 0.98 },
                                                                }}
                                                                src='https://minimal-assets-api.vercel.app/assets/illustrations/illustration_dashboard.png'
                                                            />
                                                        </CardActionArea>
                                                    ) : (
                                                        <>
                                                            <IconBullet />
                                                            {item.title}
                                                        </>
                                                    )}
                                                </ListItemStyle>
                                            </NextLink>
                                        ))}
                                    </List>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Popover>
            </>
        )
    }
    if (title === "Features") {
        return (
            <>
                <LinkStyle
                    onClick={onOpenFeatures}
                    sx={{
                        display: "flex",
                        fontWeight: 500,
                        cursor: "pointer",
                        alignItems: "center",
                        ...(isHome && { color: "common.white" }),
                        ...(isOffset && { color: "text.primary" }),
                        ...(isOpenFeatures && { opacity: 0.48 }),
                    }}
                >
                    Features
                    <Iconify
                        icon={isOpenFeatures ? "eva:arrow-ios-upward-fill" : "eva:arrow-ios-downward-fill"}
                        sx={{ ml: 0.5, width: 16, height: 16 }}
                    />
                </LinkStyle>

                <Popover
                    open={isOpenFeatures}
                    anchorReference='anchorPosition'
                    anchorPosition={{ top: 100, left: 0 }}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    onClose={onCloseFeatures}
                    PaperProps={{
                        sx: {
                            px: 3,
                            pt: 5,
                            pb: 3,
                            right: 16,
                            m: "auto",
                            borderRadius: 2,
                            maxWidth: (theme) => 300, //theme.breakpoints.values.lg,
                            boxShadow: (theme) => theme.customShadows.z24,
                        },
                    }}
                >
                    <Grid container spacing={3}>
                        {children.map((list) => {
                            const { subheader, items } = list

                            return (
                                <Grid key={subheader} item xs={12} md={12}>
                                    <List disablePadding>
                                        <ListSubheader
                                            disableSticky
                                            disableGutters
                                            sx={{
                                                display: "flex",
                                                lineHeight: "unset",
                                                alignItems: "center",
                                                color: "text.primary",
                                                typography: "overline",
                                            }}
                                        >
                                            <IconBullet type='subheader' /> {subheader}
                                        </ListSubheader>

                                        {items.map((item) => (
                                            <NextLink key={item.title} href={item.path} passHref>
                                                <ListItemStyle
                                                    underline='none'
                                                    sx={{
                                                        ...(isActive(item.path) && {
                                                            color: "text.primary",
                                                            typography: "subtitle2",
                                                        }),
                                                    }}
                                                >
                                                    {item.title === "Case studies" ? (
                                                        <CardActionArea
                                                            sx={{
                                                                py: 5,
                                                                px: 10,
                                                                borderRadius: 2,
                                                                color: "primary.main",
                                                                bgcolor: "background.neutral",
                                                            }}
                                                        >
                                                            <Box
                                                                component={m.img}
                                                                whileTap='tap'
                                                                whileHover='hover'
                                                                variants={{
                                                                    hover: { scale: 1.02 },
                                                                    tap: { scale: 0.98 },
                                                                }}
                                                                src='https://minimal-assets-api.vercel.app/assets/illustrations/illustration_dashboard.png'
                                                            />
                                                        </CardActionArea>
                                                    ) : (
                                                        <>
                                                            <IconBullet />
                                                            {item.title}
                                                        </>
                                                    )}
                                                </ListItemStyle>
                                            </NextLink>
                                        ))}
                                    </List>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Popover>
            </>
        )
    }
    const onContactUsClick = () => {
        openPopupWidget({ url: "https://calendly.com/flowpoint-vlad/intro" })
        if (typeof window !== "undefined" && window.fbq) {
            window.fbq("track", "ContactUsButton")
        }
    }

    if (title === "Contact us") {
        return (
            <ButtonStyle
                onClick={onContactUsClick}
                sx={{
                    borderRadius: "40px",
                    ...(isHome && { color: "common.white" }),
                    ...(isOffset && { color: "text.primary" }),
                }}
            >
                {title}
            </ButtonStyle>
        )
    }
    if (title === "Log in") {
        return (
            <NextLink href={path} passHref>
                <LinkStyle
                    target='_blank'
                    rel='noopener'
                    sx={{
                        fontWeight: 500,
                        ...(isHome && { color: "common.white" }),
                        ...(isOffset && { color: "text.primary" }),
                        ...(isActive(path) && {
                            color: "primary.main",
                        }),
                    }}
                >
                    {title}
                </LinkStyle>
            </NextLink>
        )
    }

    return (
        <NextLink href={path} passHref>
            <LinkStyle
                sx={{
                    fontWeight: 500,
                    ...(isHome && { color: "common.white" }),
                    ...(isOffset && { color: "text.primary" }),
                    ...(isActive(path) && {
                        color: "primary.main",
                    }),
                }}
            >
                {title}
            </LinkStyle>
        </NextLink>
    )
}
