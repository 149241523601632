// next
import { useRouter } from "next/router"
// @mui
import { styled, useTheme, alpha } from "@mui/material/styles"

import { Box, Button, AppBar, Toolbar, Container } from "@mui/material"
import { m } from "framer-motion"
import Link from "next/link"
import styledComponent from "styled-components"
// hooks
import useOffSetTop from "../../hooks/useOffSetTop"
import useResponsive from "../../hooks/useResponsive"
// utils
import cssStyles from "../../utils/cssStyles"
// config
import { HEADER } from "../../config"
// components
import Logo from "../../components/Logo"
import Label from "../../components/Label"
//
import MenuDesktop from "./MenuDesktop"
import MenuMobile from "./MenuMobile"
import navConfig from "./MenuConfig"
import { varHover, varTranHover } from "../../components/animate"
import { DASHBOARD_URL } from "../../config"
import { openPopupWidget, InlineWidget } from "react-calendly"
import { gtag } from "../../utils/analytics"

// const StyledButton = styledComponent(Button)`
// color:white;
// border:1px solid ${({ isoffsetheader, ...rest }) => isoffsetheader  ? '#0a2540' : '#5BE584'};
// background: ${({ isoffsetheader, ...rest }) => isoffsetheader ? '#0a2540' : '#5BE584'};
// margin - right: 15px;
// :hover{
//     border: 1px solid  ${({ isoffsetheader, ...rest }) => isoffsetheader  ? '#122920' : '#5BE584'};
//     background: ${({ isoffsetheader, ...rest }) => isoffsetheader  ? '#122920' : '#5BE584'};
//     color: white;

// }

// `

const ApiKeyText = styledComponent.div`
position:absolute;
top:80px;
z-index:1090;
height: auto;
width: 160px;
border-radius: 50%;
pointer-events:none;
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
transform: scale(${(props) => (props.shouldHide ? 0 : 1)});
transition:1s;
img{
    
    position:absolute;
    min-width: 160px;
    max-width:160px;
    height: auto;
}

`

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    height: HEADER.MOBILE_HEIGHT,

    transition: theme.transitions.create(["height", "background-color"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    // [theme.breakpoints.up("md")]: {
    //   height: HEADER.MAIN_DESKTOP_HEIGHT_MAIN,
    // },
}))

const ToolbarShadowStyle = styled("div")(({ theme }) => ({
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    margin: "auto",
    borderRadius: "50%",
    position: "absolute",
    width: `calc(100 % - 48px)`,
    boxShadow: theme.customShadows.z8,
}))

const StyledButton = styled(Button)(({ theme }) => ({
    color: "white",
    border: "1px solid",
    marginRight: "15px",
}))

// ----------------------------------------------------------------------

export default function MainHeader({ currentOffer }) {
    const { offsetTop: isOffset, offsetHeader: isOffsetHeader } = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT)

    const theme = useTheme()

    const { pathname } = useRouter()

    const isDesktop = useResponsive("up", "md")

    const isHome = pathname === "/"
    const isBestAiTools = pathname === "/best-ai-tools"
    const isHomePartners = pathname === "/partners"
    const isPaid = pathname.startsWith("/p/")
    const isGpts = pathname.startsWith("/gpts/")
    const isHomeEvents = pathname.startsWith("/events/")
    const isFeatures = pathname.startsWith("/features/")
    const isCompetitor = pathname.startsWith("/comparison/")

    const getDashboardUrl = () => {
        return `${DASHBOARD_URL}/auth/register`
    }
    const onContactUsClick = () => {
        openPopupWidget({ url: "https://calendly.com/flowpoint-vlad/intro" })
        if (typeof window !== "undefined" && window.fbq) {
            window.fbq("track", "ContactUsButton")
        }
    }
    const onClick = () => {
        openPopupWidget({ url: "https://calendly.com/flowpoint-vlad/intro" })
        if (typeof window !== "undefined" && window.fbq) {
            window.fbq("track", "TalkToSalesButton")
        }
        gtag_report_conversion()
    }

    function gtag_report_conversion() {
        gtag("event", "conversion", {
            send_to: "AW-11014059734/aCWdCKC1tJ0YENbt9IMp",
        })
        return false
    }

    return (
        <AppBar sx={{ boxShadow: "none", marginTop: currentOffer ? (isDesktop ? 6 : 10) : 2 }} style={{ background: "none" }} elevation={0}>
            <ToolbarStyle
                disableGutters
                sx={{
                    height: {
                        md: !isOffset ? HEADER.MAIN_DESKTOP_HEIGHT_MAIN : HEADER.MAIN_DESKTOP_HEIGHT,
                        xs: !isOffset ? HEADER.MOBILE_HEIGHT_MAIN : HEADER.MOBILE_HEIGHT,
                        sm: !isOffset ? HEADER.MOBILE_HEIGHT_MAIN : HEADER.MOBILE_HEIGHT,
                    },
                }}
            >
                <Container
                    style={{
                        borderRadius: 10,
                        padding: 10,
                        width: "100%",
                        transition: "1s",

                        ...(isOffset
                            ? {
                                  border: "1px solid rgb(211 217 218 / 24%)",
                                  boxShadow: "none",
                                  //   backdropFilter: `blur(${6}px)`,
                                  //  WebkitBackdropFilter: `blur(${6}px)`, // Fix on Mobile
                                  backgroundColor: alpha("#00150D", 0.8), // Navbar color
                                  height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
                              }
                            : {}),
                        ...(isOffsetHeader
                            ? {
                                  // background: "lightgrey",
                                  //     boxShadow:
                                  //         "0 0 2px rgb(150 150 150 / 80%), 0 4px 12px rgb(255 255 255 / 36%), inset 0 0 0 0.5px rgb(237 237 237 / 36%)",
                                  backdropFilter: `blur(${6}px)`,
                                  WebkitBackdropFilter: `blur(${6}px)`, // Fix on Mobile
                                  backgroundColor: alpha("#fff", 0.8),
                                  height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
                              }
                            : {}),
                    }}
                    sx={{
                        ml: isDesktop ? "auto" : 1,
                        mr: isDesktop ? "auto" : 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Logo hasTitle={true} isOffset={isOffset} isOffsetHeader={isOffsetHeader} />

                    <Box sx={{ flexGrow: 1 }} />

                    {isDesktop && !isPaid && !isGpts && (
                        <MenuDesktop
                            isOffset={isOffsetHeader}
                            isHome={isHome || isFeatures || isCompetitor || isHomePartners || isHomeEvents || isBestAiTools}
                            navConfig={navConfig}
                        />
                    )}

                    {/* <div style={{ position: "relative" }}>
            <ApiKeyText>
              <img src={isOffsetHeader ? "/images/getApiKeyblue.png" : "/images/getApiKeygreen.png"} />
            </ApiKeyText>
          </div> */}
                    {isDesktop && !isPaid && !isGpts && (
                        <m.div
                            variants={varHover(1.2)}
                            transition={varTranHover()}
                            whileTap={{ scale: 0.9 }}
                            whileHover={{
                                scale: 1.1,
                                transition: { duration: 0.2 },
                            }}
                        >
                            {isHomePartners ? (
                                <StyledButton
                                    variant='contain'
                                    sx={{
                                        backgroundImage: isOffsetHeader ? "#122920" : "#00150D",
                                        borderColor: isOffsetHeader ? "#122920" : "#122920",
                                        borderRadius: "40px",
                                        "&:hover": {
                                            borderColor: isOffsetHeader ? "#122920" : "#122920",
                                            background: isOffsetHeader ? "#122920" : "#122920",
                                            color: "white",
                                        },
                                    }}
                                    href='https://flowpoint-ai.getrewardful.com/signup'
                                    target='_blank'
                                    isoffsetheader={isOffsetHeader}
                                >
                                    Become a Partner
                                </StyledButton>
                            ) : isHomeEvents || isBestAiTools ? (
                                <StyledButton
                                    variant='contain'
                                    sx={{
                                        backgroundImage: isOffsetHeader ? "#122920" : "#00150D",
                                        borderColor: !isOffsetHeader ? "#EAFF00" : "#122920",
                                        borderRadius: "40px",
                                        color: !isOffsetHeader ? "white" : "#122920",
                                        "&:hover": {
                                            borderColor: isOffsetHeader ? "#122920" : "#122920",
                                            background: isOffsetHeader ? "#122920" : "#122920",
                                            color: "white",
                                        },
                                    }}
                                    // onClick={onContactUsClick}
                                    //  href='https://dashboard.flowpoint.ai/auth/register'
                                    target='_blank'
                                    onClick={() => onClick()}
                                    isoffsetheader={isOffsetHeader}
                                >
                                    Talk to sales
                                </StyledButton>
                            ) : (
                                <StyledButton
                                    onClick={() => {
                                        if (typeof window !== "undefined" && window.fbq) {
                                            window.fbq("track", "GetStartedButton")
                                        }
                                    }}
                                    variant='contain'
                                    sx={{
                                        fontWeight: 400,
                                        backgroundImage: isOffsetHeader ? "#00150D" : "#00150D",
                                        borderColor: isOffsetHeader ? "#00150D" : "#EAFF00",
                                        color: isOffsetHeader ? "#00150D" : "#EAFF00",
                                        borderRadius: "40px",
                                        "&:hover": {
                                            borderColor: isOffsetHeader ? "#122920" : "#fff",
                                            background: isOffsetHeader ? "#122920" : "1463B2",
                                            color: "white",
                                        },
                                    }}
                                    // onClick={onContactUsClick}
                                    href='https://dashboard.flowpoint.ai/auth/register'
                                    target='_blank'
                                    // href={onContactUsClick()}
                                    isoffsetheader={isOffsetHeader}
                                >
                                    Start your free trial
                                </StyledButton>
                            )}
                        </m.div>
                    )}

                    {!isDesktop && <MenuMobile isOffset={isOffsetHeader} isHome={isHome} navConfig={navConfig} />}
                </Container>
            </ToolbarStyle>

            {/* {isOffset && <ToolbarShadowStyle />} */}
        </AppBar>
    )
}
