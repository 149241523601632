import { IS_PROD } from "../config"
// ----------------------------------------------------------------------

export const gtag = (...args) => {
    try {
        if (!IS_PROD) {
            console.log("demo gtag event with args ", args)
            return
        }
        if (!window.gtag) {
            return
        }
        window.gtag(...args)
    } catch (err) {
        console.log("could not send event ", err)
    }
}

//"Add coupon", { "couponId": "qidN8p0AI3" }
export const fpTrack = (...args) => {
    try {
        window.flowpoint.track(...args)
    } catch (err) {
        console.error("Could not send custom flowpoint event", err)
    }
}
