import { useEffect, useState, useRef } from "react"
import * as React from "react"
import { Divider, Button, Popover, Typography } from "@mui/material"
import Iconify from "./Iconify"
import styled from "styled-components"
import useResponsive from "src/hooks/useResponsive"
import { current } from "@reduxjs/toolkit"
// import NewsConsent, { Newss } from "react-News-consent";
// ----------------------------------------------------------------------

const NewsBox = styled.div`
  background: ${(props) => props.background};   //#ff4842;
  
  width: 100%;
  height: 45px;
  max-height: fit-content;
   top 0;
  right: 0;
  position: fixed;
  left: 0 !important;
  justify-content: center;
  z-index: 22;
  display: flex;
  flex-wrap: wrap;
  align-contentt: center;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 900px) {
    padding-left: 5px;
    padding-right: 5px;
    height: 65px;
    max-height: fit-content;
  }
  @media screen and (max-width: 290px) {
    min-height: 80px;
    text-align: center;
    max-height: fit-content;
    padding-left: 5px;
    padding-right: 5px;
  }
`
const NewsTextBox = styled.div``

// hardcoded offers

export default function NewsPopup({ currentOffer }) {
    const [open, setOpen] = useState(true)
    const isDesktop = useResponsive("up", "sm")

    // function to opt int
    const optIn = () => {
        setOpen(false)
    }

    // function to opt out
    const optOut = () => {
        setOpen(false)
    }

    return (
        <>
            {open && currentOffer ? (
                <a
                    target='_blank'
                    style={{
                        paddingLeft: "3px",
                        color: "#00150D",

                        zIndex: 200,
                        textDecoration: "none",
                        alignItems: "center",
                        justifyItems: "center",
                        alignContent: "center",
                        display: "flex",
                        cursor: "pointer",
                    }}
                    href={currentOffer?.content?.link || "https://dashboard.flowpoint.ai"}
                    rel='noreferrer'
                >
                    <NewsBox background={currentOffer?.theme?.background || "#9CC29B"}>
                        <NewsTextBox
                            style={{
                                maxWidth: "1300px",

                                display: "flex",
                                alignItems: "center",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                alignContent: "center",
                            }}
                        >
                            <Typography
                                component='div'
                                variant='body1'
                                sx={{
                                    color: "#00140C",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    display: "flex",

                                    flexWrap: "wrap",
                                    flexDirection: { md: "row", xs: "column", sm: "row" },
                                    "&:hover": {
                                        textDecoration: "underline",
                                    },
                                }}
                            >
                                <span
                                    style={{
                                        paddingTop: "2px",
                                        paddingBottom: "2px",
                                        paddingLeft: "7px",
                                        paddingRight: "7px",
                                        borderRadius: "4px",
                                        background: currentOffer?.theme?.label?.background || "#9CC29B",
                                        color: currentOffer?.theme?.label?.text || "#00140C",
                                        marginRight: "8px",
                                        fontWeight: "bold",
                                        fontSize: "12px",
                                    }}
                                >
                                    {" "}
                                    {currentOffer?.content?.labelText || ""}
                                </span>
                                {currentOffer?.content?.text || ""}
                            </Typography>
                        </NewsTextBox>
                    </NewsBox>
                </a>
            ) : (
                ""
            )}
        </>
    )
}
