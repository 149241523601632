import React from "react"
import PropTypes from "prop-types"
// next
import { useRouter } from "next/router"
// @mui
import { Box, Link, Container, Typography, Stack } from "@mui/material"
// components
import Logo from "../../components/Logo"
//
import MainFooter from "./MainFooter"
import MainHeader from "./MainHeader"
import SecondaryHeader from "./SecondaryHeader"
import NewsBanner from "../../components/NewsBanner"
import useResponsive from "../../hooks/useResponsive"

// ----------------------------------------------------------------------

MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

const offers = {
    blackfriday: {
        startDate: 1700784023,
        endDate: 1700953223,
        content: {
            text: "Save 50% for 3 months - Black Friday Deal",
            labelText: "Coupon: BLACK50",
        },
        theme: {
            background: "red",
            label: {
                background: "black",
                text: "white",
            },
        },
    },
    cybermonday: {
        startDate: 1701043223,
        endDate: 1701212423,
        content: {
            text: "Save 30% for 3 months - Cyber Monday Deal",
            labelText: "Coupon: CYBER30",
        },
        theme: {
            background: "#d01ed8",
            label: {
                background: "#360c49",
                text: "white",
            },
        },
    },
    christmas: {
        startDate: 1703376023,
        endDate: 1703804423,
        content: {
            text: "Save 50% for 3 months - Christmas Deal",
            labelText: "🎄 Coupon: XMAS50",
        },
        theme: {
            background: "#90a878",
            label: {
                background: "#cc210f",
                text: "#f5d695",
            },
        },
    },
    newyear: {
        startDate: 1703980823,
        endDate: 1705273223,
        content: {
            text: "Save 50% for 3 months - New Year Deal",
            labelText: "🎁 Coupon: NEWYEAR50",
        },
        theme: {
            background: "#fffff0",
            label: {
                background: "#14054c",
                text: "#ffd376",
            },
        },
    },
    freePlan: {
        startDate: 1717594452,
        endDate: 1728135252,
        content: {
            text: "Introducing the Free Plan →",
            link: "https://blog.flowpoint.ai/post/v2/free-plan/",
        },
    },
}

export default function MainLayout({ children }) {
    const { pathname } = useRouter()

    const isHome = pathname === "/"
    const isBestAiTools = pathname === "/best-ai-tools"
    const isHomePartners = pathname === "/partners"
    const isHomeEvents = pathname.startsWith("/events/")
    const isFeatures = pathname.startsWith("/features/")
    const isCompetitor = pathname.startsWith("/comparison/")
    const isPaid = pathname.startsWith("/p/")
    const isGpts = pathname.startsWith("/gpts")

    const isDesktop = useResponsive("up", "md")

    if (isDesktop === null) {
        return <div />
    }

    const today = new Date().getTime()

    let currentOffer = null

    Object.keys(offers).map((item) => {
        if (offers[item]?.startDate * 1000 <= today && offers[item]?.endDate * 1000 >= today) {
            currentOffer = offers[item]
        }
    })

    return (
        <Stack sx={{ minHeight: 1 }}>
            {isHome || isHomePartners || isFeatures || isCompetitor || isHomeEvents || isBestAiTools || isPaid || isGpts ? (
                <>
                    <NewsBanner currentOffer={currentOffer} />
                    <MainHeader currentOffer={currentOffer} />
                    {children}
                    <Box sx={{ flexGrow: 1 }} />
                    {!isPaid && <MainFooter />}
                </>
            ) : (
                <>
                    <NewsBanner currentOffer={currentOffer} />
                    <SecondaryHeader currentOffer={currentOffer} />
                    {React.Children.map(children, (child) => {
                        return React.cloneElement(child, { currentOffer })
                    })}
                    <Box sx={{ flexGrow: 1 }} />
                    <MainFooter />
                </>
            )}
        </Stack>
    )
}
